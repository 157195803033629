import './App.css';

function App() {
  return (
    <div className="body">
      <section className='main-section'>
        <img 
          src='https://admin.todayfit.fr/build/images/logo_white.png'
          className='image'></img>
        <h1 className='header'>Prochainement!</h1>
        <p className='text'>Admin? Allez à la web pour les administrateurs</p>
        <p>
          <a href="https://admin.todayfit.fr" className='btn-link'>Cliquez ICI</a>
        </p>
      </section>
    </div>
  );
}

export default App;
